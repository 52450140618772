body {
  background-color: #3D6479;
  /* background: linear-gradient(#3D6479, #4E81BD) no-repeat center fixed; */
}
.body {
  color: white;
  padding-bottom: 20;
}

.banner {
  margin: 50;
}

.section {

}

.heading {
  font-size: 1.1rem;
}
#lessons ul {
  padding: 0;
}

#lessons p {
  border-bottom: 1px solid white;
  padding-bottom: 1rem;
}

#lessons li {
  list-style-type: none;
  text-align: center;
}

#lessons .col-md {
  border-top: 1px solid white;
  border-bottom: 1px solid white;
  padding: 1rem;
}

.header {
  background: linear-gradient(#3D6479, #4E81BD) center fixed; 
}

.header-dropdown {
  background: linear-gradient(#3D6479, #4E81BD) center fixed;
}

.header-dropdown :hover {
  background-color: #4E81BD;
}

.core-values {
  display: flex;
  justify-content: space-around;
}
.services {
  display: flex;
  justify-content: space-around;
}

.value {
  width: 100%;
  text-align: center;
  flex-direction: column;
}

.value-label {
  font-size: 1.5rem;
  /* font-weight: 500; */
}
.service-label {
  font-size: 1.5rem;
}